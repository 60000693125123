import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {AuthService} from "../auth/auth.service";
import {UserLogin} from "../../shared/models/user-model";
import {TracesService} from "../../features/traces/services/traces.service";
import {Router} from "@angular/router";
import {Routes} from "../../shared/consts/routes";
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ErrorDialogComponent, ErrorDialogModel } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user$: Observable<UserLogin>;

  userData!: UserLogin;
  traceHeadInsert = 'Salió del sistema: ';
  traceUserName = 'Nombre: ';
  traceUserCategory = 'Categoría: ';
  traceUser = 'Usuario: ';
  traceEmail = 'Correo: ';
  traceTelefCelular = 'Teléfono Celular: ';
  traceUserProvince = 'Provincia: ';
  traceSeparator = ', ';

  public routes: typeof Routes = Routes;

  constructor(private authService: AuthService, private tracesService: TracesService,
    private router: Router, private notificacionService: NotificationService, private dialog: MatDialog) {
    this.user$ = this.authService.getUser();
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler) {
  //   // Get the auth token from the service.
  //   const accessToken = JSON.parse(<string>sessionStorage.getItem('accessToken'));
  //
  //   // Clone the request and replace the original headers with
  //   // cloned headers, updated with the authorization.
  //   const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
  //
  //   // send cloned request with header to the next handler.
  //   return next.handle(authReq);
  // }

  // @ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    /** urls que NO llevan token*/
    if (req.url.includes('login') || req.url.includes('categorias') || req.url.includes('provincias') || req.url.includes('refresh_token')
      || req.url.includes('administracion/factores_viaje') || req.url.includes('rt_cs_api/precio') || req.url.includes('rt_cs_api/personas/jefes_turno')
      || (req.url.includes('viajes/calcular_precio') && !req.url.includes('viajes/calcular_precio_mlc') && !req.url.includes('viajes/calcular_precio_moneda') && !req.url.includes('viajes/calcular_precio_ayuda_operadora'))) {

      return next.handle(req).pipe(catchError(error => {
        return throwError(error);
      }));

    } else if (req.url.includes('/rt_cs_api/imagenes') || req.url.includes('/rt_cs_api/calendario/procesarExcel') || req.url.includes('/rt_cs_api/operaciones/cuentas_agencia/transferir')
                || req.url.includes('/rt_cs_api/resumen_trabajo/actualizar_vales')) {//Servicios que no llevan 'Content-Type': 'application/json',

      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        return next.handle(this.addTokenWithOutContent(req, accessToken)).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse &&
            !req.url.includes('login') &&
            error.status === 401
          ) {
            return this.handle401Error(req, next, true);
          }
          else if (error.status === 403 && error.error.error.match('The Token has expired')) { // error.error.message === 'El token es inválido' &&
            return this.refreshTokenWithOutContent(req, next);
          } else {
            return throwError(error);
          }
        }));
      } else {
        return throwError(Error);
      }


    } else { /** urls que si llevan token*/
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        return next.handle(this.addToken(req, accessToken)).pipe(
          catchError(error => {

            if (error instanceof HttpErrorResponse &&
              !req.url.includes('login') &&
              error.status === 401
            ) {
              return this.handle401Error(req, next, false);
            }
            else if (error.status === 403 && error.error.error.match('The Token has expired')) { // error.error.message === 'El token es inválido' &&
              return this.refreshToken(req, next);
            } else if (error.status === 401 && (error.error.error.match('No existe ningún turno abierto')
              || error.error.error.match('No existe ningún turno abierto. Las sesiones de las operadoras se han cerrado y no es posible acceder al sistema.'))) {
              this.signOut();
              this.notificacionService.notificationError(
                'No existe ningún turno abierto. Las sesiones de las operadoras se han cerrado'
              );
              return throwError(error);
            } else if (error.status === 400 && (error.error.error.match('ya está cerrada.'))) {
              this.signOut();
              return throwError(error);
            } else {
              return throwError(error);
            }
          }));
      } else {
        this.signOut();
        return throwError(Error);
      }
    }
  }

  private addTokenWithOutContent(request: HttpRequest<any>, token: string) {
    if (!token) {
      return request.clone({
        setHeaders: {
        }
      });
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (!token) {
      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  private refreshTokenWithOutContent(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshLogin().pipe(
        switchMap((accessToken: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addTokenWithOutContent(request, accessToken));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addTokenWithOutContent(request, jwt));
        }));
    }
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshLogin().pipe(
        switchMap((accessToken: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(request, accessToken));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, addTokenWithOutContent: boolean) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (this.authService.isLoggedIn()) {
        return this.authService.refreshLogin().pipe(
          switchMap((accessToken: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(accessToken);
            if(addTokenWithOutContent){
              return next.handle(this.addTokenWithOutContent(request, accessToken));
            } else
              return next.handle(this.addToken(request, accessToken));
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.match("El token suministrado ha expirado")) {
              this.signOut();
              const dialogData = new ErrorDialogModel('Error', 'El token de acceso ha expirado. Por favor, vuelva a iniciar sesión.');
              const dialogRef = this.dialog.open(ErrorDialogComponent, {
                maxWidth: '400px',
                data: dialogData,
              });

              return dialogRef.afterClosed().pipe(
                //switchMap(() => throwError(error))
              );
            }

            return throwError(error);
          })
        );
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          if(addTokenWithOutContent){
            return next.handle(this.addTokenWithOutContent(request, jwt));
          } else return next.handle(this.addToken(request, jwt));
        }));
    }

    return next.handle(request);
  }

  public signOut(): void {
    localStorage.clear();
    this.router.navigate([this.routes.LOGIN]);
  }
}
