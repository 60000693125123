import { Injectable } from '@angular/core';
import {Observable, of, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {catchError, map, take} from "rxjs/operators";
import {ApiCodeMessage} from "../../../shared/consts/api-code-message.constant";
import {LogRequest, TraceEntity, TraceRequest} from "../../../shared/models/trace-model";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {NotificationService} from "../../../shared/services/notification.service";
import {UserLogin} from "../../../shared/models/user-model";
import {AuthService} from "../../../core/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class TracesService {

  // public user$: Observable<UserLogin>;
  userData!: UserLogin;

  constructor(private _http: HttpClient, protected ngxLoaderService: NgxUiLoaderService, private notificacionService: NotificationService,
              private authService: AuthService) {
    // this.user$ = this.authService.getUser();
  }

  handleLoginError(error: HttpErrorResponse) {
    let mensajeError = undefined;
    if (error.error instanceof ErrorEvent) {
      console.error('Error:', error.error.message);
      mensajeError = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 400:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error.error;
          break;
        case 500:
          console.error(`Error del backend, código: ${error.status} `);
          break;
        case 503:
          console.error(`Error del backend, código: ${error.status} `);
          mensajeError = ApiCodeMessage.MSG_CODE_503;
          break;
        default:
          console.error(`Error: ${error} `);
          mensajeError = error;
          break;
      }
    }
    return throwError(mensajeError);
  }

  /** Obtener listado de logs */
  getLogs(logRequest: LogRequest, pageNumber: number, pageSize: number, sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    logRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', logRequest.fechaInicio))
      : null;
    logRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', logRequest.fechaFin))
      : null;
    logRequest.aplicacionId !== undefined
      ? (queryParams = queryParams.append('aplicacionId', logRequest.aplicacionId))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceTraces + '/logs', options).pipe(
      map((data) => data.result),
      catchError(this.handleLoginError)
    );
  }

  /** Obtener listado Trazas */
  getTrace(traceRequest: TraceRequest, pageNumber: number, pageSize: number, sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    traceRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', traceRequest.fechaInicio))
      : null;
    traceRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', traceRequest.fechaFin))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(traceRequest.traceHistory ? environment.serviceTracesHistory : environment.serviceTraces, options).pipe(
      map((data) => data.result),
      catchError(this.handleLoginError)
    );
  }

  getTraces(history:boolean,sort:string,fechaInicio:any, fechaFin:any, pageNumber:number,pageSize:number, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let queryParams:any;
    if(searchParam !== ''){
       queryParams = new HttpParams().append('sort', sort)
        .append('fechaInicio', fechaInicio)
        .append('fechaFin', fechaFin)
        .append('pageNumber', pageNumber)
        .append('pageSize', pageSize)
        .append('searchParam', searchParam)
    }else{
      queryParams = new HttpParams().append('sort', sort)
        .append('fechaInicio', fechaInicio)
        .append('fechaFin', fechaFin)
        .append('pageNumber', pageNumber)
        .append('pageSize', pageSize)
    }

    const options = {
      headers: headers,
      params: queryParams,
    };

    if(history){
      return this._http.get<any>(environment.serviceTracesHistory, options).pipe(
        map((data) => data
        ),
        catchError(this.handleLoginError)

      );
    }else{
      return this._http.get<any>(environment.serviceTraces, options).pipe(
        map((data) => data),
        catchError(this.handleLoginError)
      );
    }
  }

  /** Adicionar servicio */
  addTrace(traceEntity: TraceEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceTraces, traceEntity, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleLoginError)
      );
  }

  /**
   * Metodo para obtener los datos del usuario logueado y pasarlo en el formulario de creacion
   */
  // getUserData(): any {
  //   let userData: any = undefined;
  //   const sub = this.user$
  //     .pipe(take(1))
  //     .subscribe((user: UserLogin) => (userData = user));
  //   sub.unsubscribe();
  //   return userData;
  // }

  public getUserData(): any {
    const userEncoded = localStorage.getItem('currentUser');
    if (userEncoded) {
      const user = JSON.parse(userEncoded).user;
      return JSON.parse(atob(user));
    } else {
      return null;
    }
  }

  /**
   * Metodo para registrar las trazas
   * @param contexto
   * @param detalle
   * @param codViaje
   */
  saveTrace(descripcionAccion: string, contexto: string, detalle: string, viajeId?: number, codViaje?: string,
            personaId?: number, categoriaId?: number, nombreApellidos?: string, telefCelular?: string){
    this.userData = this.getUserData();
    let trace: TraceEntity = {
      descripcionAccion: descripcionAccion,
      aplicacionId: 3,
      personaId: this.userData ? this.userData.id : personaId,
      categoriaId: this.userData ? this.userData.categoriaId : categoriaId,
      nombreApellidos: this.userData ? this.userData.nombreApellidos : nombreApellidos,
      telefCelular: this.userData ? this.userData.telefCelular: telefCelular,
      contexto: contexto,
      detalle: detalle,
      viajeId: viajeId,
      codViaje: codViaje
    };

    this.addTrace(trace)
      .subscribe({
        next: (res) => {

        },
        error: (err) => {
          this.ngxLoaderService.stop();
          this.notificacionService.notificationError(
            err.status ? 'Lo sentimos, ocurrió un error al crear la traza' : err
          );
        },
      });
  }

  /** Obtener listado de aplicaciones */
  getApplications(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceAplicacionesSistema, {headers}).pipe(
      map((data) => data),
      catchError(this.handleLoginError)
    );
  }

  getDataColumnsTableTrace() {
    return [
      'Fecha',
      'Viaje',
      'Móvil',
      'Categoría',
      'Nom.Persona',
      'Acción',
      'Contexto',
      'Aplicación',
      'IP'
    ];
  }

  getDataColumnsTableLog() {
    return [
      'Fecha',
      'Aplicacion',
      'Contexto',
      'Detalle',      
    ];
  }
}
